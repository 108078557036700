.register-form {
    background-color: #00c85a;
    padding: 30px 0px;
}
.register-form input {
    background-color: #070d1b;
    padding: 12px 25px;
    color: #fff;
    border-radius: 35px;
    border: none !important;
}
.register-form input:focus {
    outline: none !important;
}
.register-form input::placeholder {
    color: #fff;    
}
.register-form p {
    color: #070d1b;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 18px;
}

.register-form-submit button {
    background-color: #fff;
    color: #00c85a;
    font-size: 20px;
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
    padding: 7px 30px;
    border-radius: 50px;
    transition-duration: 0.6s;
    border: none;
    width: 100%;
  }
  .register-form-submit button:hover {
    background: #070d1b;
    color: #00c85a !important;
  }
  .modal-feild {
    display: block !important;
  }
  .content-head h2 {
    font-size: 47px;
    font-weight: 700;
  }

  .register-form h4 {
    font-size:47px ;
    font-weight: 700;
    color: #070d1b !important;
  }
.register-form p {
  font-size: 13px !important;
  color: red !important;
}

.padding{
  padding-right: 0px;
}
.padding1{
  padding: 0px;
}
.padding2 {
  padding: 0px 11px 0px 0px;
}
.modal-register-form .register-form .col-md-2{

    width: 33% !important;
    text-align: center !important;
}
@media only screen and (max-width: 767px) {
  .modal-register-form .register-form .col-md-2 {
  
      width: 100% !important;
      text-align: center !important;
    }

  .register-form-submit button {
    background-color: #fff;
    color: #00c85a;
    font-size: 20px;
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
    padding: 7px 30px;
    border-radius: 50px;
    transition-duration: 0.6s;
    border: none;
    width: 25%;
  }
}
@media only screen and (max-width: 991px) {
  .padding2 {
    padding: 0px 0px 0px 0px;
  }
  .modal-register-form .register-form .col-md-2 {
    
          width: 100% !important;
          text-align: center !important;
        }
}
@media only screen and (max-width: 575px){
.padding2 {
    padding: 0px 0px 0px 0px;
  }
    .modal-register-form .register-form .col-md-2 {
  
      width: 100% !important;
      text-align: center !important;
    }
    .register-form-submit button {
        background-color: #fff;
        color: #00c85a;
        font-size: 20px;
        font-weight: 600;
        font-family: 'Poppins', sans-serif;
        padding: 7px 30px;
        border-radius: 50px;
        transition-duration: 0.6s;
        border: none;
        width: 35%;
      }
}