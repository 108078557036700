bun{
    position: relative;
    background-color: #fff;
}
.mymodal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* border: 1px solid #ccc;
    background: #fff; */
    overflow: auto;
    border-radius: 4px;
    outline: none;
    padding: 20px;
    z-index: 9999;
}

.myoverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
}

.ReactModal__Overlay {
    opacity: 0;
    transition: opacity 500ms ease-in-out;
}

.ReactModal__Overlay--after-open {
    opacity: 1;
}

.ReactModal__Overlay--before-close {
    opacity: 0;
}

.ReactModal__Content.ReactModal__Content--after-open.mymodal.ww .col-md-3 {
    padding-left: 0px !important;
    width: 80%;
    margin: 0px auto;
  }
.ReactModal__Content.ReactModal__Content--after-open.mymodal.ww .col-md-2 {
  padding-right: 0px;
  width: 100% !important;
  max-width: 250px !important;
  margin: 0px auto;
}
  

  @media only screen and (max-width:767px) {
    .ReactModal__Content.ReactModal__Content--after-open.mymodal.ww .register-form-submit button {
        width: 100% !important;
        max-width: 150px;
      }
      .ReactModal__Overlay--after-open .modal-register-form .register-form .row .col-md-3 .email-field {
        text-align: center !important;
      }
      .ReactModal__Content--after-open .modal-register-form .register-form .row .col-md-3 input {
        width: 100% !important;
        max-width: 250px !important;
      }
      .ReactModal__Content--after-open .modal-register-form .register-form .row .col-md-3 {
        text-align: center !important;
      }
  }

  @media only screen and (max-width:375px) {
    .ReactModal__Content.ReactModal__Content--after-open.mymodal.ww .register-form .col-md-3 input {
        width: 100% !important;
      }
  }