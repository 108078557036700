@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
.App {
  margin: 0px auto;
  padding: 0px;
  font-family: "Roboto", sans-serif;
  overflow: hidden;
}
*::after,
*::before {
  box-sizing: border-box;
}
p,
ul,
ol,
li {
  margin: 0px !important;
  padding: 0px !important;
}
a:hover {
  color: none;
}
img {
  margin: 0px;
  padding: 0px;
  text-decoration: none !important;
  color: #fff !important;
}
a {
  margin: 0px;
  padding: 0px;
  text-decoration: none !important;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0px !important;
  padding: 0px !important;
}
ul,
li {
  list-style: none;
}
.container {
  width: 100% !important;
  max-width: 1170px !important;
  margin: 0px auto;
}
.profession-type {
  margin-top: 25px !important;
}

.after-474-vis {
  display: none;

}
.become-text {
  color: #fff;
}
.after-375-vis {
  display: none !important;
}
.after-474-vis img{
  width: 100% !important;
}
.categories-blk {
  margin-top: 25px;
}
/* .profession-inner {
  padding: 5px;
  border-radius: 50px;
  background-color: #fff;
} */
.iframer-video {
  width: 100% !important;
  min-width: 500px !important;
  height: 400px !important;
}
.same-type-pro h5 {
  min-height: 48px;
  display: flex;
  align-items: center;
  color: #000 !important;
  justify-content: center !important;
  transition-duration: 0.2s;
}
.same-type-pro {
  background-color: #fff;
  border-radius: 50px;
  padding: 10px;
  border: 5px solid #00c58a;
  transition-duration: 0.6s;

}
.same-type-pro:hover h5 {
  color: #fff !important;
}
.same-type-pro:hover {
  background: #00c75a !important; 
  border: 5px solid #2c4a73 !important;
  transform: translateY(-15px) ;

}
.img-up-tra {
  transition-duration: 0.6s;
  cursor: pointer !important;
}
.img-up-tra:hover {
  transform: translateY(-15px) ;
  transform: scale(1.2);
}
.no-fonter {
  font-family: "Roboto", sans-serif;
}
.youtube {
  width: 100% !important;
}

.youtube img {
  cursor: pointer !important;
}
/* ----HOVERS----- */
.thankyou-img img {
  width: 100%;
  max-width: 150px;
} 
.thankyou-sec {
  padding: 60px 0px;
  background: #070d1b;
}
.thankyou-head h2 {
  color: #00c85a;
  font-weight: 700;
  font-size: 56px;
margin-bottom: 40px !important;
}
.thankyou-sec {
  padding: 60px 0px;
  background: #070d1b;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.thankyou-para p {
  font-size: 25px !important;
  color: #fff;
}
.thankyou-card {
  padding: 40px;
}
.thankyou-img {
  margin-bottom: 50px !important;
}
.detail-blk button:hover {
  border: 2px solid #00c58a;
  background: transparent;
  color: #00c58a !important;
}
.chart-join button:hover {
  border: 2px solid #00c58a;
  background: transparent;
  color: #00c58a !important;
}
.register-now button:hover {
  border: 2px solid #00c58a;
  background: transparent;
  color: #00c58a !important;
}
.sec-10-register button:hover {
  border: 2px solid #00c58a;
  background: transparent;
  color: #00c58a !important;
}
.left-content-blk button:hover {
  border: 2px solid #00c58a;
  background: transparent;
  color: #00c58a !important;
}
/* =======traingle-block--======= */

.site-logo .site-img img {
  width: 100% !important;
  max-width: 500px;
}
.content-sub-4 {
  background-image: url(./Components/images/sec-4-back.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 40px 0px;
  display: none;
}
.similar-rect {
  background: #2b4b74;
  padding: 25px 10px;
  border: 5px solid #fff;
  border-radius: 10px;
}
.rectangle-2 {
  background: #3db65a;
  padding: 25px 10px;
  border: 5px solid #fff;
  border-radius: 10px;
  margin: 20px 0px;
}
.similar-rect p span {
  background-color: #00c85a;
  font-size: 30px;
  padding: 15px 30px;
  border-radius: 10px;
}
.rectangle-1 p,
.rectangle-2 p,
.rectangle-3 p {
  font-size: 30px;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  display: inline-block;
}
.rectangle-2 p span {
  background-color: #2c4a73;
  font-size: 30px;
  padding: 15px 30px;
  border-radius: 10px;
}
.image-resp {
  margin: 0px auto;
}
.image-resp img {
  width: 100%;
  max-width: 500px !important;
  display: none !important;
}
/* =========Header-styles====start== */

.speakers-header h1 {
  color: #00c85a;
  font-weight: 700;
  font-size: 56px;
}
.head-sec-2 {
  width: 100%;
  max-width: 900px !important;
  margin-top: 40px !important;
  margin: 0px auto;
  position: relative;
  border: 2px dashed #fff;
  padding: 40px 0px;
}
.head-sec-2 h3 {
  color: #00c85a;
  font-size: 34px;
  font-family: "Poppins", sans-serif;
  margin-top: -22px !important;
  margin-bottom: 25px !important;
}
.pricing h5 .green-color {
  color: #00c85a;
}
.head-sec-2 p {
  font-size: 30px;
  font-weight: 700;
  font-style: italic;
  line-height: 36px;
}
.head-sec-2 p span {
  color: #00c85a;
}
.speakers-header {
  background-image: url(./Components/images/headerbackground.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 40px 0px;
}
.head-sec-2 {
  width: 100%;
  max-width: 900px !important;
  margin-top: 50px !important;
  position: relative;
  border: 2px dashed #fff;
  padding: 40px 0px;
  display:inline-block;
  margin:20px 0;
  border-top: none !important;

}
.head-sec-2 h3 {
  color: #00c85a;
  font-size: 34px;
  font-family: "Poppins", sans-serif;
  margin-top: -60px !important;
  margin-bottom: 25px !important;
}

.head-sec-2 h3 {
  font-size:30px;
  margin: -0.65em auto -0.45em;
  overflow: hidden;
  text-align: center;
  line-height: 1.2em;
}
.head-sec-2 h3:before,
.head-sec-2 h3:after {
  content: "";
  vertical-align: top;
  display: inline-block;
  width: 50%;
  height: 0.65em;
  border-bottom: 2px dashed #fff;
  margin: 0 2% 0 -55%;
}
.head-sec-2 h3:after {
  margin: 0 -55% 0 2%;
}
/* =============Header-styles====END===== */

/* =========Content-Styles====Start===== */

.content-sec-1 {
  background-image: url(./Components/images/sec-1-back-2.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 40px 0px;
}
.content-sec-1 .image-blk img {
  width: 100%;
}
.content-sec-2 {
  padding: 60px 0px;
  background-color: #070d1b;
}
.content-sec-3 {
  background-image: url(./Components/images/sec-3-back.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 40px 0px;
}
.content-sec-4 {
  background-image: url(./Components/images/sec-4-back.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 40px 0px;
}
.content-sec-5 {
  padding: 40px 0px;
  background-color: #070d1b;
}
.content-sec-6 {
  background-image: url(./Components/images/sec-6-back.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 40px 0px;
}
.content-sec-7 {
  background-image: url(./Components/images/sec-7-back.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 40px 0px;
}
.content-sec-8 {
  background-image: url(./Components/images/sec-8-back.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 40px 0px;
}
.content-sec-9 {
  background: #070d1b;
  padding: 40px 0px;
}
.content-sec-10 {
  background-image: url(./Components/images/sec-10-back.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 40px 0px;
}
.content-sec-11 {
  background: #070d1b;
  padding: 40px 0px;
}
.site-content .content-head h2 {
  font-size: 47px;
  font-weight: 700;
  color: #00c85a;
}
.coach-details h2 {
  color: #00c85a;
  font-size: 41px;
  font-weight: 700;
}
.valpoints-blk p span {
  color: #00c85a;
}
.valpoints-blk {
  margin-top: 70px;
}
.valpoints-blk p {
  font-size: 20px;
  font-weight: 600;
}
.valpoints-blk .point-1,
.valpoints-blk .point-2 {
  margin-bottom: 20px;
}
.valpoints-blk .fa {
  background: #00c85a;
  padding: 6px;
  border-radius: 20px;
  font-size: 18px;
  margin-right: 15px !important;
  margin-left: 5px;
}
.valpoints-blk .point-1,
.valpoints-blk .point-2,
.valpoints-blk .point-3 {
  border: 1px solid #7a95b9;
  padding: 25px 5px;
}
.detail-blk button {
  display: inline-block;
  background-color: #00c85a;
  color: #fff;
  font-size: 24px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  padding: 8px 50px;
  border-radius: 50px;
  border: 2px solid #00c85a;
  transition-duration: 0.6s;
}
.detail-5 .circles {
  background-color: #2d4b73;
  border-radius: 65px;
  padding: 22px;
  width: 100%;
  max-width: 112px !important;
}
.gurantee-blk img {
  width: 100%;
  max-width: 400px;
}
.detail-5 .circles p {
  font-size: 32px;
  font-weight: 600;
  line-height: 32px;
}
.detail-5 .circles p span {
  font-size: 18px !important;
}
.content-sec-2 .content-head h2 {
  margin-bottom: 50px !important;
}
.detail-1 p span,
.coach-details p span {
  color: #00c85a;
}
.detail-1 p span {
  font-size: 24px;
  font-weight: 700;
}
.skiller {
  border-right: none !important;
}
.detail-1 p,
.detail-2 p,
.detail-6 p,
.coach-details p {
  font-size: 18px;
  font-weight: 600;
}
.detail-1, .detail-2, .detail-3, .detail-4, .detail-5 {
  margin-bottom: 35px;
}
.detail-4 p {
  font-size: 24px;
  font-weight: 600;
}
.detail-blk p,
.coach-details p {
  font-family: "Poppins", sans-serif;
}
.coach-img img {
  border-radius: 100%;
  margin-bottom: 20px;
  width: 100%;
  max-width: 310px;
}
.coach-details h2 {
  margin-bottom: 20px !important;
}
.content-sec-3 .companies {
  width: 100% !important;
  max-width: 210px !important;
}
.companies img {
  width: 100%;
}
.content-sec-3 .content-head {
  margin-bottom: 40px;
}
.content-sec-4 p {
  font-size: 22px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
}
.content-sec-4 .col-md-8,
.content-sec-4 .col-md-4 {
  padding: 0px !important;
}
.second-point {
  position: relative;
  top: 205px;
  left: 65px;
}
.third-point {
  position: relative;
  top: 290px;
}
.first-point {
  position: relative;
  top: 100px;
  right: 125px;
}
.chart-join button {
  display: inline-block;
  background-color: #00c85a;
  color: #fff;
  font-size: 24px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  padding: 8px 50px;
  border-radius: 50px;
  border: 2px solid #00c85a;
  transition-duration: 0.6s;
}
.chart-join {
  margin-top: 40px;
}
.second-text p {
  color: #222222;
  font-size: 18px;
  font-family: "Poppins", sans-serif;
}
.number-marg p {
  font-size: 41px;
  font-weight: 600;
}
.gurantee-blk img {
  width: 100%;
}

.testimonial-block p {
  width: 100%;
  margin: 0px auto !important;
  min-height: 288px !important;
  font-size: 16px !important ;
  color: #000;
  font-weight: 500;
  margin-bottom: 15px !important;
}
.testimonial-block p br {
  display: none !important;
}
.number1 p,
.number3 p {
  background-color: #00c75a;
  border-radius: 50px;
  padding: 6px 26px !important;
}
.number2 p {
  background-color: #2c4a73;
  border-radius: 50px;
  padding: 6px 26px !important;
}
.group-images img {
  width: 100%;
}
.growth-blk {
  background: #fff;
  padding: 10px 15px;
  border-radius: 50px;
}
.three-points-right {
  margin-top: 55px;
}

.img-point1,
.img-point2 {
  margin-bottom: 135px;
}
.number-marg {
  margin-right: 20px;
}
.register-now button {
  display: inline-block;
  background-color: #00c85a;
  font-size: 29px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  padding: 18px 25px;
  border-radius: 50px;
  border: 2px solid #00c58a;
  transition-duration: 0.6s;
}
.content-sec-6 .content-head h2 {
  margin-bottom: 5px !important;
}
.content-sec-6 .content-head p {
  font-size: 21px;
  font-weight: 500;
}
.content-sec-6 .content-head {
  margin-bottom: 50px;
}
.content-sec-6 .youtube1 {
  margin-bottom: 40px;
}
.content-sec-6 img {
  height: 177px !important;
  width: 100% !important;
}
.testimonial-back {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 90% !important;
  margin: 0px auto;
  padding-top: 80px;
  border-radius: 25px; 
}

.test-image {
  margin-top: -100px;
}
.testimonial-back-inner {
  background-color: #fff;
  padding: 25px !important;
  border-radius: 15px !important;
}
.testimonial-back .fa {
  color: #ffcf0d !important;
  font-size: 24px;
  margin: 30px 15px 15px 0px;
}
.reviewer h4 {
  font-size: 24px;
  font-weight: 600;
  color: #00c58a !important;
}
.testimonial-block .review {
  margin: 0px auto;
  width: 100% !important;
  margin-top: 10px;
}
.testimonial-block .review a {
  color: #00c75a !important;
}
.content-sec-7 .content-head {
  margin-bottom: 40px;
}
.testimonial-block img {
  /* position: relative;
  top: 102px; */
  width: 100%;
  max-width: 140px;
  /* left: -62px; */
  border: 5px solid #00c75a;
  border-radius: 100px;
}
.reviewer-position {
  /* position: relative;
  top: 112px !important; */
  width: 100%;
  max-width: 140px;
  /* left: -63px !important; */
  border: 10px solid #00c75a;
  border-radius: 100px;
}
.test-image img {
  margin: 0px auto;
}
.content-sec-8 .content-head h3 {
  font-size: 41px;
  font-weight: 600;
  color: #00c85a;
  margin-top: 20px !important;
}
.skills {
  border-right: 2px solid #6f737b;
}
.skills p span {
  font-size: 40px;
  font-weight: 600;
  color: #00c85a;
}
.skills p {
  font-size: 20px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  min-height: 120px;
}
.no-border {
  border-right: none !important;
}
.merge-down {
  margin-bottom: 80px;
}
.saurabh-image {
  margin-bottom: 50px;
}
.skills img {
  margin-bottom: 10px;
}
.down-marg {
  margin-bottom: 50px;
}
.content-sec-9 .content-head {
  margin-bottom: 40px;
}
.content-sec-9 .content-head2 {
  margin-top: 40px;
}
.content-sec-9 .content-head2 h2 {
  font-size: 35px;
  font-weight: 700;
  color: #00c85a;
}
.message-blk {
  background-image: url(./Components/images/message-blk-back.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  padding: 100px 0px;
}
.message-blk img {
  margin-top: -111px;
  margin-right: 10px;
}
.message-blk p {
  font-size: 33px;
  font-weight: 500;
  min-height: 148px;
}
.content-sec-10 .content-head {
  margin-bottom: 40px;
}
.sec-10-register button {
  display: inline-block;
  background-color: #00c85a;
  font-size: 29px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  padding: 18px 25px;
  border-radius: 50px;
  border: 2px solid #00c58a;
  transition-duration: 0.6s;
}
.pricing {
  margin-top: 40px;
}
.pricing h5 {
  font-size: 37px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  margin-bottom: 40px !important;
}
.left-content-blk h5 {
  font-size: 43px;
  font-weight: 700;
  color: #00c85a;
  margin-bottom: 40px !important;
}
.left-content-blk p {
  font-size: 23px;
  line-height: 39px;
}
.left-content-blk button {
  display: inline-block;
  background-color: #00c85a;
  font-size: 24px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  padding: 8px 25px;
  border-radius: 50px;
  margin-top: 40px !important;
  border: 2px solid #00c58a;
  transition-duration: 0.6s;
}
.content-head3 h2 {
  font-size: 47px;
  font-weight: 600;
}
.content-head3 {
  margin-bottom: 30px;
}

/* ---Cotent-styles-end======== */

/* ==========Footer ===Styles======== */
.speakers-footer .content-head h2 {
  font-size: 47px;
  font-weight: 600;
  color: #00c85a;
}
.footer-border-content {
  border: 2px dashed #fff;
  padding: 30px 15px;
}
.speakers-footer {
  background-image: url(./Components/images/footer-back.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 40px 0px;
}
.letter-content p {
  font-size: 15px;
  font-family: "Poppins", sans-serif;
  line-height: 25px;
}
.letter-heading h5 {
  font-size: 30px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
}
.letter-heading {
  margin-top: 40px;
  margin-bottom: 30px;
}
.letter-content a {
  color: #00c85a;
}
.letter-content a:hover{
text-decoration: underline !important;
text-decoration-color:#00c85a !important;
 
}

/* ============Footer-----Styles ====End */

/* -----====Media Queries====------- */

@media only screen and (max-width: 1200px) {
  /* .register-form-inner .col-md-3 {
      flex: 0 0 auto;
      width: 28%;
    }
  
    .register-form-inner .col-md-2 {
      flex: 0 0 auto;
      width: 16%;
    } */
  .container {
    width: 100% !important;
    max-width: 992px !important;
  }
  .first-3-img {
    width: 100% !important;
    min-width: 560px !important;
  }
  .message-blk img {
    margin-top: -70px;
    margin-right: 10px;
    width: 50px;
  }
  .message-blk p {
    font-size: 31px;
    font-weight: 500;
    min-height: 130px;
    line-height: 40px;
    margin-top: 15px !important;
  }
  .letter-heading {
    margin-top: 20px;
    margin-bottom: 30px;
  }
  .skills p {
    font-size: 17px;
  }

  .three-points-right {
    margin-top: 35px;
  }
  .img-point1,
  .img-point2 {
    margin-bottom: 90px;
  }
  .content-sec-4 p {
    font-size: 20px;
  }
  .chart-image img {
    width: 400px;
  }
  .second-point {
    top: 180px;
  }
  .first-point {
    top: 84px;
  }
  .third-point {
    top: 262px;
  }
  .detail-5 .circles p {
    font-size: 26px;
  }
  .detail-5 .circles {
    padding: 17px;
    max-width: 100px !important;
  }
  .detail-1,
  .detail-2,
  .detail-3,
  .detail-4,
  .detail-5 {
    margin-bottom: 35px;
  }
  .detail-1 p,
  .detail-2 p,
  .detail-6 p,
  .coach-details p {
    font-size: 16px;
  }
  .coach-img img {
    width: 100% !important;
    max-width: 310px !important;
  }
  .valpoints-blk p br {
    display: none;
  }
}

@media only screen and (max-width: 991px) {
  .container {
    width: 100% !important;
    max-width: 768px !important;
  }
  .testimonial-block p {
    min-height: 312px !important;
  }
  .first-3-img {
    width: 100% !important;
    min-width: 440px !important;
  }
  .content-sec-1 .col-md-6:first-child {
    display: flex;
    align-items: center !important;
  }
  .head-sec-2 {
    margin-top: 50px !important;
  }
  .valpoints-blk {
    margin-top: 40px;
  }
  .speakers-header {
    padding: 50px 0px;
  }
  .valpoints-blk p {
    font-size: 18px;
  }
  .site-content .content-head h2 {
    font-size: 37px;
  }
  .content-sec-9 .content-head h2,
  .content-sec-9 .content-head2 h2 {
    font-size: 30px;
  }
  .content-head3 h2 {
    font-size: 40px;
  }
  .message-blk {
    padding: 50px 0px !important;
  }
  .message-blk img {
    margin-top: -20px;
    margin-right: 6px;
    width: 42px;
  }
  .pricing h5 {
    font-size: 30px;
    margin-bottom: 25px !important;
  }
  .sec-10-register button {
    font-size: 25px;
    padding: 15px 20px;
  }
  .left-content-blk button {
    font-size: 20px;
    padding: 10px 25px;
    margin-top: 30px !important;
  }
  .left-content-blk p {
    font-size: 20px;
    line-height: 35px;
  }
  .left-content-blk h5 {
    font-size: 40px;
    margin-bottom: 20px !important;
  }
  .skills img {
    margin-bottom: 0px !important;
  }
  .skills p span {
    font-size: 30px;
  }
  .skills p {
    font-size: 15px;
  }
  .register-now button {
    font-size: 25px;
    padding: 15px 25px;
  }
  .content-sec-8 .content-head h3 {
    font-size: 35px;
  }
  .chart-image img {
    width: 300px;
  }
  .second-point {
    top: 164px;
    left: -30px;
  }
  .content-sec-4 p {
    font-size: 15px;
  }
  .first-point {
    top: 64px;
    right: 90px;
  }
  .second-point {
    top: 140px;
    left: 35px;
  }
  .third-point {
    top: 196px;
  }
  .chart-join button {
    font-size: 22px;
    padding: 10px 40px;
  }
  .three-points-right p br {
    display: none;
  }
  .number1 p,
  .number3 p {
    padding: 5px 20px !important;
  }
  .number-marg p {
    font-size: 30px;
  }
  .number2 p {
    padding: 10px 25px !important;
  }
  .second-text p {
    font-size: 16px;
  }
  .growth-blk {
    padding: 8px 10px;
  }
  .img-point1,
  .img-point2 {
    margin-bottom: 68px;
  }
  .detail-blk button {
    font-size: 22px;
    padding: 8px 45px;
  }
  .coach-details h2 {
    font-size: 32px;
  }
  .coach-details p br {
    display: none;
  }
  .detail-5 .circles {
    padding: 10px;
    max-width: 90px !important;
  }
  .detail-5 .circles p {
    font-size: 25px;
  }
  .detail-5 .circles p span {
    font-size: 16px !important;
  }
}

@media only screen and (max-width: 767px) {
  .padding {
      padding: 0px;
    }
    .email-field {
      text-align: start !important;
    }
    .testimonial-block p {
      min-height: 273px !important;
      font-size: 14px !important;
    }
    .no-rh-border{
      border-right: none !important;
    }
    .detail-1,
    .detail-2,
    .detail-3,
    .detail-4,
    .detail-5 {
      margin-bottom: 20px;
    }
      .register-form-submit button {
        padding: 9px 30px !important;
      }
    .register-form .col-md-3 {
      width: 50%;
    }
    .register-form .col-md-2 {
      width: 50%;
    }
    .register-form .col-md-2 .register-form-submit button {
      width: 70% !important;
    }
    .register-form .col-md-3 input {
      width: 90% !important;
    }
    /* .content-sec-1 .row {
      flex-direction: column-reverse;
    } */

  .container {
    width: 100% !important;
    max-width: 675px !important;
  }
  .first-3-img {
    width: 100% !important;
    min-width: 400px !important;
  }

  .register-form .row {
    flex-wrap: wrap;
  }
  .register-form .col-md-3 input{
    width: 70% !important;
  }
  .register-form .col-md-3 {
    text-align: center;
  }
  .speakers-header h1 {
    font-size: 50px;
  }
  .head-sec-2 {
    margin-top: 40px !important;
  }
  .head-sec-2 p {
    font-size: 28px;
  }
  .head-sec-2 h3 {
    font-size: 30px;
  }
  .detail-5 .circles p {
    font-size: 22px;
    font-weight: 600;
    line-height: 25px;
  }
  .detail-5 .circles {
    padding: 13px 8px;
    max-width: 83px !important;
  }
  .coach-img img {
    width: 100% !important;
    max-width: 300px !important;
  }
  .content-sec-4 {
    display: none;
  }
  .content-sub-4 {
    display: block;
  }
  .content-sub-4 .content-head {
    margin-bottom: 40px;
  }
  .content-sec-6 img {
    height: auto !important;
    width: 94% !important;
  }
  .content-sec-2 .row {
    flex-direction: column-reverse;
  }
  .content-sec-2 .detail-blk {
    margin-top: 30px;
  }
  .content-sec-6 .youtube {
    margin-bottom: 40px;
  }

  .no-border {
    border-right: 2px solid #6f737b !important;
  }
  .down-marg {
    margin-bottom: 25px;
  }
  .sec-10-register button {
    font-size: 20px;
    padding: 12px 18px;
  }
  .pricing h5 {
    font-size: 26px;
    margin-bottom: 22px !important;
  }
  .message-blk img {
    margin-top: -33px;
    margin-right: 6px;
    width: 47px;
  }
  .left-content-blk {
    text-align: center !important;
  }
  .gurantee-blk {
    text-align: center !important;
  }
  .gurantee-blk img {
    width: 100% !important;
    max-width: 300px !important;
  }
  .gurantee-blk {
    text-align: center !important;
    margin-bottom: 30px;
  }
  .number-marg p {
    font-size: 27px;
  }
  .img-point1,
  .img-point2 {
    margin-bottom: 50px;
  }
  .register-now button {
    font-size: 20px;
    padding: 10px 20px;
  }
  .image-blk {
    margin: 0px auto;
  }
  .valpoints-blk .point-1,
  .valpoints-blk .point-2,
  .valpoints-blk .point-3 {
    padding: 25px 10px;
  }
.valpoints-blk {
  margin-top: 30px;
}
  .content-sec-2 {
    padding: 40px 0px;
    background-color: #070d1b;
  }
  .content-sec-11 .row {
    flex-direction: column-reverse;
  }
}

@media only screen and (max-width: 674px) {
  .container {
    width: 100% !important;
    max-width: 575px !important;  
  }
  .reviewer h4 {
    font-size: 22px;
  }
  .testimonial-block p {
    min-height: 336px !important;
    font-size: 14px !important;
  }
  .content-sec-5 .row .col-sm-3 {
    width: 33%;
  }

  .speakers-header h1 {
    font-size: 40px;
  }
  .first-3-img {
    width: 100% !important;
    min-width: 335px !important;
  }
  .head-sec-2 h3 {
    font-size: 25px;
  }
  .head-sec-2 p {
    font-size: 22px;
  }
  .speakers-header {
    padding: 35px 0px;
  }
  .site-content .content-head h2 {
    font-size: 33px;
  }
  .rectangle-1 p,
  .rectangle-2 p,
  .rectangle-3 p {
    font-size: 24px;
  }
  .rectangle-2 p span {
    font-size: 25px;
  }
  .similar-rect p span {
    font-size: 25px;
  }
  .second-text p {
    font-size: 13px;
  }
  .number1 p,
  .number3 p {
    padding: 5px 16px !important;
  }
  .number-marg p {
    font-size: 24px;
  }
  .number2 p {
    padding: 6px 16px !important;
  }
  .number-marg {
    margin-right: 10px;
  }
  .img-point1,
  .img-point2 {
    margin-bottom: 45px;
  }
  .three-points-right {
    margin-top: 22px;
  }
  .content-sec-9 .content-head h2,
  .content-sec-9 .content-head2 h2 {
    font-size: 25px;
  }
  .content-head3 h2 {
    font-size: 33px;
  }
  .left-content-blk h5 {
    font-size: 30px;
    margin-bottom: 20px !important;
  }
  .left-content-blk p {
    font-size: 18px;
    line-height: 32px;
  }
  .left-content-blk button {
    font-size: 18px;
    padding: 10px 20px;
    margin-top: 30px !important;
  }
  .speakers-footer .content-head h2 {
    font-size: 38px;
  }
  .letter-heading h5 {
    font-size: 25px;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
  }
}

@media only screen and (max-width: 575px) {
  .iframer-video {
    width: 100% !important;
    min-width: 350px !important;
    height: 250px !important;
  }
  .register-form h4 {
    font-size: 40px !important;
  }
  .email-field {
    text-align: center !important;
  }
  .after-474-novis {
    display: none !important;
  }
  .after-474-vis {
    display: block !important;
  }
  .testimonial-block p {
    font-size: 16px !important;
    min-height: 168px !important;
  }
  .container {
    width: 100% !important;
    max-width: 475px !important;
  }
  .content-sec-5 .row .col-sm-3 {
    width: 50%;
  }
  .same-type-pro h5 {
    font-size: 15px;
    min-height:36px !important;
  }
    .register-form .col-md-3 {
      width: 100%;
    }
    .register-form .col-md-2 {
      width: 100%;
    }
    .right-side-col {
      text-align: center !important;
      padding-right: 0px !important;
    }
    .register-form-submit button {
      padding: 9px 30px !important;
    }
    .left-side-col {
      text-align: center !important;
      padding-left: 0px !important;
    }
  .three-points-right {
    display: none;
  }
  .head-sec-2 h3::before, .head-sec-2 h3::after{
    display: none;
  }
  .head-sec-2 {
    width: 100%;
    margin-top: 40px !important;
    margin: 0px auto;
    position: relative;
    border: 2px dashed #fff;
    padding: 20px 0px;
    border-top: 2px dashed #fff !important;
  }
  .head-sec-2 h3 {
    color: #00c85a;
    font-size: 23px;
    font-family: "Poppins", sans-serif;
    margin-top: 0px !important;
    margin-bottom: 10px !important;
  }

  .content-sec-3 .col-sm-4 {
    width: 50%;
    margin-bottom: 15px;
  }
  .rectangle-1 p,
  .rectangle-2 p,
  .rectangle-3 p {
    font-size: 18px;
  }
  .similar-rect {
    padding: 20px 10px;
  }
  .rectangle-2 {
    padding: 20px 10px;
  }
  .rectangle-2 p span {
    padding: 10px 25px;
  }
  .similar-rect p span {
    padding: 10px 25px;
  }
  .content-sub-4 .content-head {
    margin-bottom: 30px;
  }
  .content-sec-1,
  .content-sec-2,
  .content-sec-3,
  .content-sec-4 .content-sec-5,
  .content-sec-6,
  .content-sec-7,
  .content-sec-8 .content-sec-9,
  .content-sec-10,
  .content-sec-11 {
    padding: 30px 0px;
  }
  .three-points-left {
    margin-top: 25px;
  }
  .img-point1,
  .img-point2 {
    margin-bottom: 30px;
  }
  .content-sec-5 .register-now {
    margin-top: 30px;
  }
  .content-sec-8 .col-sm-4 {
    width: 50%;
  }
  .content-sec-8 .col-sm-4:last-child {
    margin-top: 20px;
  }
  .merge-down {
    margin-bottom: 40px;
  }
  .down-marg {
    margin-bottom: 0px !important;
  }
  .group-images {
    margin-bottom: 20px !important;
  }
  .content-sec-9 .content-head2 {
    margin-top: 0px !important;
  }
  .content-sec-10 .col-sm-6 {
    margin-bottom: 15px;
  }
  .content-sec-10 .col-sm-6:last-child {
    margin-bottom: 0px;
  }
  .sec-10-register button {
    font-size: 18px;
    padding: 10px 18px;
  }
  .second-text p {
    font-size: 15px;
  }
  .chart-join button {
    font-size: 20px;
  }
  .image-resp img{
    display: block !important;
  }
  .three-points-left img {
    display: none;
  }
}

@media only screen and (max-width: 474px) {
  .container {
    width: 100% !important;
    max-width: 375px !important;
  }
  .register-form h4 {
    font-size: 30px !important;
  }
  .after-474-vis {
    display: none !important;
  }
  .after-375-vis {
    display: block !important;
  }
  .after-375-vis img {
    width: 100% !important;
  }
  .detail-blk button {
    font-size: 18px;
    padding: 5px 20px;
  }
  .detail-5 .circles p span {
    font-size: 14px !important;
  }
  .detail-5 .circles p {
    font-size: 20px;
  }
  .detail-5 .circles {
    padding: 10px 8px;
    max-width: 75px !important;
  }
  .image-resp h2 {
    padding: 35px 30px !important;
    font-size: 27px;
  }
  .speakers-header h1 {
    font-size: 32px;
  }
  .head-sec-2 h3 {
    font-size: 22px;
    line-height: 30px;
  }
  .head-sec-2 p br {
    display: none;
  }
  .site-content .content-head h2 {
    font-size: 30px;
  }
  .similar-rect p span {
    padding: 5px 15px;
    margin-right: 10px !important;
  }
  .rectangle-2 p span {
    padding: 5px 15px;
    margin-right: 10px !important;
  }
  .rectangle-1 p,
  .rectangle-2 p,
  .rectangle-3 p {
    font-size: 16px;
  }
  .chart-join button {
    font-size: 18px;
    padding: 8px 26px;
  }
  .content-sec-8 .content-head h3 {
    font-size: 24px;
  }
  .content-sec-9 .content-head h2,
  .content-sec-9 .content-head2 h2 {
    font-size: 25px !important;
  }
}

@media only screen and (max-width: 500px) {
  .ReactModal__Content .modal-register-form .register-form {
    min-width: 400px !important;
  }
  .ReactModal__Content .modal-register-form .register-form .content-head h2 {
    font-size: 35px;
    font-weight: 700;
  }
}

@media only screen and (max-width: 400px) {
  .ReactModal__Content .modal-register-form .register-form {
    min-width: 320px !important;
  }
  .register-form-submit button {
    font-size: 18px !important;
    padding: 6px 25px !important;
  }
}
@media only screen and (max-width: 365px) {
  .rectangle-1 p,
  .rectangle-2 p,
  .rectangle-3 p {
    font-size: 14px;
  }
  .similar-rect p span {
    padding: 3px 12px;
    margin-right: 10px !important;
  }
  .similar-rect p span {
    font-size: 22px;
  }
  .rectangle-2 p span {
    font-size: 22px;
  }
}

@media only screen and (max-width: 375px) {
  .skills {
    border-right: none !important;
  }
  .content-head h2 {
    font-size: 35px !important;
    font-weight: 700;
  }
  .pricing h5 {
    font-size: 22px;
    margin-bottom: 15px !important;
  }
}
/* ----=======MODAL-STYLES=======---- */


.modal-register-form .register-form .register-form-submit {
  text-align: center !important;
}
.modal-register-form .close-button button {
  background: #fff;
  border: none;
  padding: 8px 16px;
  border-radius: 50px;
  color: #070d1b !important;
  box-shadow: 1px 2px 16px 5px #00000054;
  font-size: 18px;
  font-weight: 600;
  position: absolute;
  right: 0;
  top: -20px;
  transition-duration: 0.6s !important;
}
.mymodal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 40px !important;
  z-index: 9999;
}
.modal-register-form {
  position: relative;
}
.modal-register-form .close-button button:hover {
  background: #070d1b;
  color: #fff !important;
  transform: rotate(90deg);
}
.modal-register-form .register-form .row {
  display: block;
}
.modal-register-form .register-form .row .col-md-3 {
  width: 100%;
  margin-bottom: 20px;
}
.modal-register-form .register-form {
  padding: 25px !important;
  border-radius: 25px !important;
  width: 100% !important;
  min-width: 500px !important;
}

/* -----------input-feild-number-styles============ */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield !important;
}
/* 
.head-sec-2::after {
  content: "";
  position: absolute;
  width: 135px;
  height: 197px;
  border: 2px dashed #fff;
  border-right: none;
  top: 1px;
  left: -135px;
}
.head-sec-2::before {
  content: "";
  width: 135px;
  position: absolute;
  height: 197px;
  border: 2px dashed #fff;
  right: -135px;
  top: 1px;
  border-left: none;
}
.head-sec-2 h3 {
  color: #00c85a;
  font-size: 34px;
  font-family: 'Poppins', sans-serif;
  margin-bottom: 20px !important;
  position: relative ; 
  top :-40px ; 
  
}
.head-sec-2 {
  width: 100%;
  max-width: 630px !important;
  margin-top: 40px !important;
  margin: 0px auto;
  position: relative;
  border-bottom: 2px dashed #fff;
  padding: 40px 0px;
} */

/* @keyframes rotate {
  100% {
    transform: rotate(1turn);
 }
} */

.triangle-responsive-img img {
  width: 100%;
}
.ReactModal__Content.ReactModal__Content--after-open.mymodal.ww .register-form .col-md-3 {
  animation: none !important;
  visibility: visible !important;
}
.ReactModal__Content.ReactModal__Content--after-open.mymodal.ww .register-form .col-md-3 .name-field {
  animation: none !important;
  visibility: visible !important;
}


.modal-register-form .register-form .animate__fadeInUp{
  visibility: visible !important;
  animation-delay: 1s;
  animation-name: none;
}


@media only screen and (max-width:480px) {
  .slick-next {
    right: 0px !important;
  }
  .slick-prev {
    left: 0px !important;
  }
}