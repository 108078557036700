.please-wait {
    width: 100% !important;
    margin: 0px auto;
    text-align: center !important;
    color: #fff;
}
.please-wait h5 {
    font-size: 18px;
    font-weight: 500;
}

.please-wait .spinner .fa {
    font-size: 55px;
}